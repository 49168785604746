<template>
  <div>
    <BaseList
      type="order"
      :searchTerm.sync="search"
      :noData="orderCount === 0"
      :isLoading="orderCount === null"
      noDataText="order.no-order-found"
      :pagination="false"
      :filterCount="isAdmin ? 1 : 0"
      @doSearch="searchOrder"
    >
      <template v-if="isAdmin" v-slot:filter1>
        <BaseDatePicker
          range
          showTypeSelection
          :monthPicker.sync="selectMonthPicker"
          v-model="date"
          :label="
            selectMonthPicker ? 'order.month-created' : 'order.date-created'
          "
          @input="getList('updateAutoRange')"
        />
      </template>
      <v-row>
        <v-col
          v-for="(order, index) in orders"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          ><OrderListItem
            :order="order"
            @donePrint="onDonePrint(index)"/></v-col
      ></v-row>
    </BaseList>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OrderListItem from '@/components/orders/OrderListItem'
import OrderService from '@/services/modules/OrderService'
import {
  displayErrorMessage,
  fullTextSearch,
  hasRole,
  setAutoRange,
  updateAutoRange
} from '@/utils'

export default {
  name: 'OrderList',

  components: {
    OrderListItem
  },

  data: () => ({
    orders: null,
    initList: [],
    selectedOrder: {},
    addRequestDialog: false,
    search: '',
    selectMonthPicker: false,
    date: null
  }),

  computed: {
    ...mapState('common', ['rangeType', 'selectedDate', 'tables']),

    orderCount() {
      if (Array.isArray(this.orders)) {
        return this.orders.length
      } else {
        return null
      }
    },

    isAdmin() {
      return hasRole('Admin')
    },

    orderType() {
      return this.$route.path.split('/').pop()
    }
  },

  mounted() {
    if (this.rangeType === 'monthly') {
      this.selectMonthPicker = true
    }
    this.date = setAutoRange(this.rangeType, this.selectedDate)
    this.getList()
    this.$mitt.on('refreshList', this.getList)
    this.$mitt.on('cancelOrder', this.cancel)
    this.$mitt.on('updateOrder', this.update)
    this.$mitt.on('updateHasWaiting', this.updateHasWaiting)
    this.$mitt.on('createInvoice', this.onCreateInvoice)
  },
  beforeDestroy() {
    this.$mitt.off('refreshList', this.getList)
    this.$mitt.off('cancelOrder', this.cancel)
    this.$mitt.off('updateOrder', this.update)
    this.$mitt.off('updateHasWaiting', this.updateHasWaiting)
    this.$mitt.off('createInvoice', this.onCreateInvoice)
  },

  methods: {
    ...mapActions('common', ['updateTable']),

    getList(val) {
      if (val === 'updateAutoRange') {
        updateAutoRange(this.selectMonthPicker, this.date)
      }
      const conditions = {
        date: this.date,
        byMonth: this.selectMonthPicker
      }
      OrderService.getList(this.orderType, conditions)
        .then(res => {
          const { docs } = res.data
          this.orders = docs
          this.initList = docs
          this.toRefresh = false
        })
        .catch(err => displayErrorMessage(err))
    },

    update(order) {
      const index = this.orders.findIndex(x => x._id === order._id)
      this.orders.splice(index, 1, order)
      this.selectedOrder = order
    },

    cancel(order) {
      const { tableCode, _id } = order
      const index = this.orders.findIndex(x => x._id === _id)
      this.orders.splice(index, 1)
      this.updateTable({ tableCode, orderInfo: null })
      this.$socket.client.emit('update-table', this.tables)
    },

    searchOrder() {
      this.filterOrder()
    },

    filterOrder() {
      this.orders = this.initList.filter(order => {
        const { tableName, customerName, submittedBy, orderNum } = order
        return fullTextSearch(
          this.search,
          [tableName, customerName, submittedBy],
          [orderNum]
        )
      })
    },

    onDonePrint(index) {
      this.orders[index].hasWaiting = null
      this.orders.splice(index, 1, this.orders[index])
    },

    updateHasWaiting(payload) {
      const { orderId, hasWaiting } = payload
      const index = this.orders.findIndex(x => x._id === orderId)
      this.orders[index].hasWaiting = hasWaiting
    },

    onCreateInvoice() {
      this.$router.push({ name: 'orders-invoices' })
    }
  },

  sockets: {
    refreshList() {
      this.getList()
    }
  }
}
</script>

<style scoped></style>
