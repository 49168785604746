<template>
  <div>
    <v-card
      :color="$helper.setBgColor(orderStatus)"
      @click="$mitt.emit('openViewOrder', { item: order, type: 'order' })"
    >
      <v-card-actions v-if="order.status !== 'cancelled'">
        <BaseIconButton icon-name="delete" @action="openCancelOrder" />
        <v-spacer />
        <BaseIconButton
          icon-name="edit"
          @action="
            $mitt.emit('openViewOrder', {
              item: order,
              type: 'order',
              update: true
            })
          "
        />
      </v-card-actions>
      <v-card-title :class="order.status !== 'cancelled' ? 'pt-0' : ''">{{
        order.tableName
      }}</v-card-title>
      <v-card-text class="text-body-1 black--text">
        <BaseEntry label="order.customer" :content="order.customerName" />
        <BaseEntry label="order.order-num" :content="order.orderNum" />
        <BaseEntry
          label="order.check-in"
          :content="$helper.formattedDate(order.createdAt)"
        />
        <BaseEntry label="order.creator" :content="order.submittedBy" />
        <BaseEntry v-if="order.note" label="order.note" :content="order.note" />
      </v-card-text>
      <v-card-actions v-if="orderStatus !== 'cancelled'">
        <BaseIconButton
          icon-name="moreRequests"
          @action="$mitt.emit('openAddRequest', { item: order, type: 'order' })"
        />
        <v-spacer />
        <BaseIconButton
          v-if="showPrint && order.hasWaiting"
          icon-name="print"
          @action="print = true"
        />
        <v-btn
          v-else-if="showPrint && order.hasWaiting === null"
          color="secondary"
          text
          @click.stop="print = true"
          >{{ $t('common.reprint') }}</v-btn
        >
        <BaseIconButton v-else icon-name="receipt" @action="closeOrder" />
      </v-card-actions>
    </v-card>
    <KitchenRequestList
      v-if="print"
      v-show="false"
      :orderId="order._id"
      :printReady="printReady"
      :reprint="!order.hasWaiting"
      container="print"
      :fullScreen="false"
      @readyToPrint="toPrint"
    />
  </div>
</template>

<script>
import KitchenRequestList from '@/components/kitchen-requests/KitchenRequestList'
import { isWindows, isMacOs } from 'mobile-device-detect'

export default {
  name: 'OrderListItem',

  props: {
    order: Object
  },

  components: {
    KitchenRequestList
  },

  data: () => ({
    print: false,
    printReady: false
  }),

  computed: {
    showPrint() {
      return (isWindows || isMacOs) && this.order.status !== 'cancelled'
    },

    orderStatus() {
      const { status, hasWaiting } = this.order
      if (status === 'serving') {
        if (hasWaiting) {
          return 'hasWaiting'
        } else if (hasWaiting === null) {
          return 'cooking'
        } else {
          return 'serving'
        }
      } else {
        return status
      }
    }
  },

  methods: {
    openCancelOrder() {
      const item = {
        type: 'cancelOrder',
        data: this.order,
        confirmTitle: 'order.cancel-title',
        confirmContent: this.$t('order.cancel-text', {
          req: `${this.order.tableName} - ${this.order.customerName}`
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    },

    toPrint(val) {
      if (val) {
        this.printReady = true
      } else {
        this.print = false
        this.printReady = false
        this.$emit('donePrint')
      }
    },

    closeOrder() {
      const item = {
        type: 'createInvoice',
        data: this.order._id,
        confirmTitle: 'order.close-order-title',
        confirmContent: this.$t('order.close-order-text', {
          order: `${this.order.tableName} - ${this.order.customerName}`
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    }
  }
}
</script>

<style scoped></style>
